/** @jsx jsx */
import { jsx } from "theme-ui"
import { Link } from "gatsby"

import Seo from "../components/seo"
import Layout from "../components/layout"

const Thanks = () => (
  <Layout className="thanks-page">
    <Seo title="Thank you" />
    <div
      className="wrapper"
    >
      <div className="contact-content">
        <h2>Enquiry submitted</h2>
        <p>
          Thank you! Your enquiry form was successfully submitted. A member of our team will be in contact with you shortly.
        </p>
        <p>
          <Link to="/">
            <button className="button button-electric-blue">
              Back to home
            </button>
          </Link>
        </p>
      </div>
    </div>
  </Layout>
)

export default Thanks
